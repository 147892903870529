@use '/styles/mixin/fonts.module.scss' as *;

.d-inline-price-info {
  @apply flex;
  @apply flex-col;
  @apply gap-1;

  &__heading {
    @apply text-center;
    @include font-text('mobile', 'medium');

    @screen md {
      @apply text-left;
      @include font-text('mobile', 'small');
    }

    @screen xl {
      @include font-text('desktop', 'small');
    }
  }

  &__price {
    @apply flex;
    @apply gap-0.5;
    @screen md {
      @apply gap-1.5;
    }

    @screen xl {
      @apply gap-2;
    }

    &__unit {
      @include font-heading('mobile', 'h3');

      @screen md {
        @include font-subtext('mobile', 'large');
      }

      @screen xl {
        @include font-heading('desktop', 'h3');
      }
    }

    &__label {
      @apply text-gray-400;
      @apply flex;
      @apply pb-1;
      @apply items-end;
      @include font-caption('mobile', 'bold');

      @screen md {
        @apply pb-0;
        @apply text-left;
        @include font-subtext('mobile', 'small');
      }

      @screen xl {
        @apply pb-1;
        @include font-caption('desktop', 'normal');
      }
    }
  }
}
