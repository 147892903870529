@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/truncate.module.scss' as *;
.drive-testimonials-block {
  &__container {
    @apply flex;
    @apply flex-row;

    @apply w-full;
    @apply min-w-[272px];
    @screen md {
      @apply min-w-[284px];
    }
    @screen lg {
      @apply min-w-[272px];
    }

    @apply min-h-[272px];
    @apply max-h-[330px];

    @apply py-0;
    @apply px-2;
  }

  &__customer-review {
    @apply flex;
    @apply flex-col;

    @apply p-8;
    @apply rounded-2xl;
    @apply w-full;
    border: 1px solid #e3e3e3;
    box-shadow: 0px 2px 16px 0px #00000014;
  }

  &__customer-details {
    @apply flex;
    @apply flex-row;
    @apply items-center;
  }
  &__customer-img img {
    @apply w-8;
    @apply mr-2;
    @apply bg-white;
  }
  &__customer-name {
    @apply m-0;
    @apply text-black;

    @apply font-normal;
    @apply font-primary;
    @apply text-lg;
    @apply font-semibold;
    @apply leading-[22px];
    @apply text-left;
  }
  &__customer-content {
    @apply overflow-hidden;
    @apply mt-4;
  }
  &__customer-content p {
    @apply m-0;
    @include truncate(8);

    @apply font-normal;
    @apply text-left;

    @apply text-base;
    @apply leading-6;
    @screen md {
      @apply text-lg;
      @apply leading-[27px];
    }
  }
}
