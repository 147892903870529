.drive-article-loading-card {
  &__wrapper {
    @apply flex;
    @apply flex-row;
    @apply h-full;
  }
  &__img-wrapper {
    @apply w-full;
    @apply relative;
    @apply bg-gray-200;
    &:hover {
      @apply cursor-pointer;
    }

    @screen md {
      @apply h-[324px];
      @apply max-w-[930px];
    }
    @screen xl {
      @apply h-[343px];
    }
  }
  &__link-wrapper {
    @apply flex;
    @apply items-center;
    @apply justify-center;

    @apply px-10;
    @apply text-white;
    @apply w-full;
    @apply max-h-[324px];
    @apply max-w-[25%];
    @apply bg-black;
    @screen md {
      @apply h-[324px];
    }
    @screen xl {
      @apply h-[343px];
    }
  }
}
