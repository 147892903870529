@use '/styles/mixin/fonts.module.scss' as *;
.drive-nused-info-box {
  &__container {
    @apply z-80;
    @apply absolute;
    @apply cursor-default;
    @apply right-0;
    @apply p-4;
    @screen l {
      margin-right: calc(calc(100vw - 1440px) / 2);
    }
  }

  &__compact {
    @apply hidden;

    &--show {
      @apply flex;
      @apply flex-row;
      @apply gap-x-2;
      @apply text-white;
      @apply items-center;
      @apply cursor-pointer;
    }

    &--text {
      @include font-caption('mobile', 'normal');
      @apply font-normal;
      @apply opacity-60;
      line-height: 15px;
      @screen md {
        @include font-caption('desktop', 'normal');
        @apply text-small;
        @apply font-normal;
      }
    }

    &--icon {
      @apply w-[22px];
      @apply h-[22px];
    }
  }

  &__full {
    @apply h-[108px];
    @apply w-[320px];
    @apply rounded-2xl;
    @apply flex;
    @apply flex-nowrap;
    @apply flex-col;
    @apply gap-y-2;
    @apply bg-[#E5F7F7];
    border: 2px solid #cceded;
    @apply overflow-hidden;
    white-space: pre-line;
    @apply hidden;
    @screen lg {
      @apply h-[109px];
      @apply w-[340px];
    }

    &--show {
      @apply flex;
      animation: 1s slide-left-mobile;

      @screen lg {
        animation: 1s slide-left-desktop;
      }
      @keyframes slide-left-mobile {
        from {
          @apply w-0;
        }
        to {
          @apply w-[320px];
        }
      }
      @keyframes slide-left-desktop {
        from {
          @apply max-w-0;
        }
        to {
          @apply max-w-[340px];
        }
      }
    }
    &__wrapper {
      @apply flex;
      @apply flex-col;
      @screen lg {
        @apply pt-0;
        @apply gap-y-0;
      }
    }

    &__content {
      @apply w-full;
      @apply flex;
      @apply flex-row;
      @apply items-center;
      @apply px-4;
      @apply pt-4;
    }

    &--heading {
      @apply w-full;
      @apply text-teal-dark;
      @apply m-0;
      @include font-caption('mobile', 'bold');
      line-height: 15px;
      @apply ml-2;

      @apply overflow-hidden;
      overflow-wrap: normal;
      @apply h-full;
      @apply w-full;
      @apply max-h-[18px];
      @apply min-w-[244px];

      @screen lg {
        @apply min-w-[208px];
        @apply max-h-[15px];
      }
    }

    &--text {
      @apply overflow-hidden;
      overflow-wrap: anywhere;
      @apply text-teal-dark;
      @include font-text('mobile', 'small');
      line-height: 15px;
      @apply ml-[26px];
      @apply h-full;
      @apply w-[272px];
      @apply pb-4;
      @apply px-4;
      @screen lg {
        @apply min-w-[308px];
        @apply min-h-[50px];
      }
    }
    &--icon {
      @apply w-4;
      @apply h-4;

      &__wrapper {
        @apply min-w-4;
        @apply h-4;
        @apply m-[1px];
        @apply bg-teal-dark;
        @apply rounded-full;
        @apply cursor-pointer;
      }
      &__link {
        @apply bg-[#E5F7F7];
      }
    }
  }
}
