@use '/styles/mixin/fonts.module.scss' as *;

.drive-price-rating-label {
  &__wrapper {
    @apply pointer-events-none;
    @apply pl-1;
    @apply pr-1;
    @apply py-[3px];
    @apply min-w-[99px];
    @apply h-full;
    @apply w-full;
    @apply border-[2px];
    @apply border-solid;
    @apply border-teal-faint;
    @apply box-border;
    @apply rounded;
    @apply flex;
    @apply gap-x-[3px];
    @apply items-center;
  }

  &__text {
    @apply text-teal-dark;
    @apply h-full;
    @apply flex;
    @apply items-center;
    @include font-caption('desktop', 'normal');
    &--in-dml-slider {
      @apply whitespace-nowrap;
    }
  }
}
