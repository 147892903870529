@use '../../../../styles/variables.scss' as *;

.d-ad {
  &__ad-wrapper {
    @apply w-full;
    @apply flex;
    @apply justify-center;
  }
  &__sticky {
    @apply w-full;
    @apply flex;
    @apply justify-center;
    @apply bg-white;
    @apply sticky;
    @apply top-0;
    @apply z-50;
    @apply overflow-visible;
    @apply py-10px;
    @media (min-width: 600px) {
      @apply w-screen;
    }
    @screen md {
      @apply w-full;
      @apply py-0;
      @apply static;
      @apply bg-transparent;
    }
    &--under-header {
      top: $header-height-mobile;
    }
  }
  &__not-sticky {
    @apply w-full;
    @apply flex;
    @apply justify-center;
  }
  &__hr {
    @apply m-0;
    @apply border-none;
    @apply transition-all;
    &--no-data {
      @apply h-0 #{!important};
    }
  }
}
