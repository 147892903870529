.drive-sponsored-content {
  &__ad-spacing {
    @apply relative;
    left: calc(-50vw + 50%);
    @apply w-screen #{!important};
    @screen l {
      @apply max-w-[1440px];
      margin-left: calc((100vw - 1440px) / 2);
    }
  }
  &__take-over {
    @screen lg {
      @apply max-w-[1240px];
      margin-left: calc((100vw - 1240px) / 2);
    }
  }
}
