@use '/styles/mixin/fonts.module.scss' as *;
.d-make-model-search {
  &__wrapper {
    @apply bg-black;
    @apply h-auto;
    @screen md {
      @apply h-[153px];
    }
    @screen lmd {
      @apply h-[106px];
    }
    @screen lg {
      @apply h-[152px];
      @apply flex-1;
      @apply flex-row;
      @apply pb-0;
    }

    @screen xl {
      @apply h-[152px];
      @apply m-0;
    }

    [class*='make-model-search_d-make-model-search__container'] {
      @apply gap-2;
      @screen md {
        @apply gap-4;
      }
      @apply p-0;
    }
  }

  &__container {
    @apply max-w-[1240px];
    @apply w-full;
    @apply h-full;
    @apply mx-auto;
    @apply flex;
    @apply flex-row;
    @apply flex-wrap;
    @apply items-center;
    @apply justify-center;
    @apply border-0;
    @apply px-5;
    @apply pb-[30px];

    @screen md {
      @apply pb-0;
      @apply px-14;
      @apply gap-x-[45px];
      @apply gap-y-0;
    }

    @screen lmd {
      @apply flex-nowrap;
      @apply gap-[65px];
    }

    @screen lg {
      @apply gap-[67px];
    }

    @screen xl {
      @apply p-0;
    }
  }

  &__btn-wrapper {
    @apply w-full;
    @screen md {
      @apply gap-4;
      @apply gap-4;
      @apply p-0;
      @apply w-14;
      @apply h-10;
      @apply flex;
      @apply justify-center;
    }
    @apply mt-2;
    @screen md {
      @apply mt-0;
      @apply w-24;
    }
    a {
      @apply flex;
      @apply items-center;
      @apply justify-center;
      @apply w-full;
      @apply h-full;
      // increase specificity to override btn class and base style
      [class*='make-model-search_d-make-model-search__btn'] {
        &:not(:disabled):hover {
          @apply bg-blue-light;
        }
      }
    }
  }
  //ensures class not being pruned
  &__btn {
    @apply w-full;
    @apply my-0;
    @apply border-white;
    @apply text-white;
    @include font-button('desktop');
    @screen md {
      @include font-subtext('mobile', 'medium');
    }
  }
}
