@keyframes cycle2 {
  0%,
  15% {
    @apply opacity-0;
  }
  20%,
  65% {
    @apply opacity-100;
  }
  70%,
  100% {
    @apply opacity-0;
  }
}

@keyframes cycle3 {
  0%,
  30% {
    @apply opacity-0;
  }
  35%,
  60% {
    @apply opacity-100;
  }
  65%,
  100% {
    @apply opacity-0;
  }
}

@keyframes cycle4 {
  0%,
  30% {
    @apply opacity-0;
  }
  35%,
  55% {
    @apply opacity-100;
  }
  60%,
  100% {
    @apply opacity-0;
  }
}

@keyframes cycle5 {
  0%,
  30% {
    @apply opacity-0;
  }
  35%,
  50% {
    @apply opacity-100;
  }
  55%,
  100% {
    @apply opacity-0;
  }
}

.d-review-inline-animation {
  &__animation-for-2 {
    animation: cycle2 8s ease-in-out infinite;

    &:nth-child(1) {
      animation-delay: -4s;
    }
    &:nth-child(2) {
      animation-delay: 0s;
    }
  }

  &__animation-for-3 {
    animation: cycle3 12s ease-in-out infinite;

    &:nth-child(1) {
      animation-delay: -8s;
    }
    &:nth-child(2) {
      animation-delay: -4s;
    }
    &:nth-child(3) {
      animation-delay: 0s;
    }
  }

  &__animation-for-4 {
    animation: cycle4 16s ease-in-out infinite;

    &:nth-child(1) {
      animation-delay: -12s;
    }
    &:nth-child(2) {
      animation-delay: -8s;
    }
    &:nth-child(3) {
      animation-delay: -4s;
    }
    &:nth-child(4) {
      animation-delay: 0s;
    }
  }

  &__animation-for-5 {
    animation: cycle5 20s ease-in-out infinite;

    &:nth-child(1) {
      animation-delay: -16s;
    }
    &:nth-child(2) {
      animation-delay: -12s;
    }
    &:nth-child(3) {
      animation-delay: -8s;
    }
    &:nth-child(4) {
      animation-delay: -4s;
    }
    &:nth-child(5) {
      animation-delay: 0s;
    }
  }
}
