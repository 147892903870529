@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/scroll.module.scss' as *;

.oly-disclaimer-wrapper {
  @apply relative;
  @apply flex;
  @apply flex-col;
  @apply items-center;

  &__container {
    @apply relative;
    @apply flex;
    @apply flex-col;
    @apply w-full;
    @apply z-10;
    @apply border;
    @apply rounded-xl;
    @apply bg-teal-faint;
    @apply border-[1px];
    @apply border-teal-light;
    @apply max-h-[504px];
    @apply mx-2.5;

    @screen lmd {
      @apply mx-0;
      @apply mt-[88px];
      @apply max-w-[704px];
      @apply max-h-[520px];
    }

    @screen lg {
      @apply max-w-[805px];
      @apply mt-[1.5rem];
    }

    &__heading {
      @apply px-7;
      @apply pt-7;
      @apply pb-3;
      @apply flex;
      @apply justify-start;
      @apply items-center;
      @apply border-b-[1px];
      @apply border-teal-light;
      @include font-subtext('desktop', 'large');

      @screen lmd {
        @apply pt-6;
        @apply pl-6;
        @apply pr-5;
      }
    }

    &__close-btn {
      @apply z-10;
      @apply absolute;
      @apply top-7;
      @apply right-6;
      @apply h-6;
      @apply w-6;
      @apply max-h-6;
      @apply max-w-6;

      @screen lmd {
        @apply top-6;
        @apply right-5;
      }
    }

    &__text-wrapper {
      @apply overflow-y-auto;
      @apply flex-1;
      @apply px-7;

      @screen lmd {
        @apply pl-6;
        @apply pr-5;
        @apply mr-4;
      }

      @include custom-scroll;

      &::-webkit-scrollbar-thumb {
        @apply bg-[#00000026];
        @apply rounded-[10px];
      }

      &__content {
        @apply mt-4;
        @include font-text('mobile', 'small');

        @screen lmd {
          @include font-text('desktop', 'small');
        }
      }
    }
  }
}
