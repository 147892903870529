@use '/styles/mixin/fonts.module.scss' as *;

.d-listing-card-specs {
  &__specs {
    @include font-heading('desktop', 'h3');
    @apply flex;
    @apply flex-col;
    @apply gap-2;
    @apply mb-3.5;
    span:not(img) {
      @apply text-gray-500;
      @apply flex;
      @include font-caption('mobile', 'normal');
      @apply gap-x-1.5;
    }
  }
}
