.d-cover-hero {
  &__wrapper {
    @apply relative;
    @apply pointer-events-none;
    @apply overflow-hidden;
    @apply w-screen;
    left: calc(-50vw + 50%);
    height: calc(100vh - 336px);
    @apply max-h-[396px];
    @apply min-h-[384px];
    background: linear-gradient(to right, #070d4c 65%, #164bad);

    @screen md {
      @apply max-h-[384px];
    }
    @screen lmd {
      @apply max-h-[491px];
    }
    @screen lg {
      height: calc(100vh - 400px);
      @apply max-h-[720px];
      @apply min-h-[550px];
      &--page-takeover {
        margin-left: calc((100vw - 1024px) / 2);
        @apply container;
      }
    }
    @screen xl {
      &--page-takeover {
        margin-left: calc((100vw - 1240px) / 2);
        @apply container;
      }
    }

    &--nused-page {
      @apply min-h-[360px];
      @apply h-[360px];
      @screen md {
        @apply min-h-[344px];
        @apply max-h-[344px];
      }
      @screen lmd {
        @apply max-h-[360px];
      }
      @screen lg {
        height: calc(100vh - 400px);
        @apply max-h-[384px];
        @apply min-h-[300px];
      }
    }

    &--image {
      @apply object-cover;
      @apply object-center;
      @apply absolute;
      @apply w-full;
      @apply h-full;
    }

    &--video {
      @apply absolute;
      @apply top-1/2;
      @apply left-1/2;
      width: 1920px #{!important}; // 2k video
      height: 1080px #{!important};
      transform: translate(-50%, -50%);
      @screen xxl {
        width: 3840px #{!important}; // 4k video
        height: 2160px #{!important};
      }
    }
    &--nused {
      background: linear-gradient(89.15deg, #051747 0%, #11429a 100%);
      @apply pointer-events-auto;
    }
  }
}
