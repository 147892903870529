@use '/styles/mixin/imageWrapper.module.scss' as *;
@use '/styles/mixin/considerModel.module.scss' as considerModel;
@use '/styles/mixin/fonts.module.scss' as *;
.drive-model-card {
  &__container {
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @apply h-full;
    @apply relative;
    @apply cursor-default;

    &--sample-image {
      [class^='imageCarousel_image-carousel__carousel-dots'] {
        @apply bottom-8;
      }
    }
  }
  @mixin buttonStyle {
    @apply flex;
    @apply flex-row;
    @apply items-center;
    @include font-caption('mobile', 'normal');
    @apply text-black;
    &:hover {
      color: unset;
    }
  }

  &__content {
    @apply px-4;
    @apply flex;
    @apply flex-col;
    @apply h-full;
    @apply pb-4;
    @apply pt-3;
    @apply bg-white;
    @apply rounded-b-2xl;
    @apply relative;
    [class^='listing-card-price-info'] {
      @apply z-[1];
    }
    &--link {
      @apply absolute;
      @apply h-full;
      @apply w-full;
      @apply top-0;
      @apply left-0;
    }
  }

  &__type {
    @apply py-[3px];
    @apply px-1.5;
    @apply rounded;
    @apply border-[2px];
    @apply border-gray-300;
    @apply text-gray-500;
    @include font-caption('desktop', 'normal');
    @apply capitalize;
    &-ghost-listing {
      @apply normal-case;
      @apply border-blue-faint;
      @apply text-blue-darkest2;
    }
  }
  &__type-container {
    @apply mb-2;
    @apply flex;
    @apply gap-x-[7px];
    @apply items-center;
  }
  &__price-rating {
    @apply max-h-6;
    @apply max-w-[103px];
  }

  &__featured-img {
    &__sticky {
      @apply bg-white;
      @apply z-10;
      @apply absolute;
    }
  }

  &__nused-tag {
    @apply h-6;
    @apply min-w-[73px];
    @apply w-[73px];
    @apply max-w-[73px];
    @apply rounded;
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply text-white;
    background: linear-gradient(87.93deg, #0a256e 22.7%, #2960c5 202.04%);

    img {
      @apply h-3;
      @apply w-15;
    }
  }
}
