@use '/styles/mixin/fonts.module.scss' as *;

.drive-rego-error-message {
  &__wrapper {
    @apply box-border;
    @apply border-coral-light;
    @apply border-2;
    @apply rounded-2xl;
    @apply bg-[#FFEAEA];
    @apply py-10px;
    @apply pl-2;
    @apply pr-4;
    @apply mb-4;
    @apply flex;
    @apply items-center;
    @apply gap-x-2;
    @screen md {
      @apply -mt-[14px];
    }
  }
  &__icon {
    @apply h-5;
    @apply w-5;
  }
  &__text {
    @include font-caption('desktop', 'normal');
    @apply text-coral-normal;
  }
}
