@use '/styles/mixin/fonts.module.scss' as *;

.d-container {
  @apply relative;
  @apply min-h-[100px];

  &__heading--wrapper {
    @apply absolute;
    @apply w-full;
    @apply top-18;
    @apply z-10;
    @apply text-white;
    @apply text-center;
    h1 {
      @include font-heading('mobile', 'h2');
      @screen md {
        @include font-heading('mobile', 'h1');
      }
      @screen lg {
        @include font-heading('desktop', 'h1');
      }
    }
    h2 {
      @include font-heading('mobile', 'h4');
      @screen lmd {
        @include font-heading('mobile', 'h3');
      }
      @screen lg {
        @include font-heading('desktop', 'h3');
      }
    }
    h3 {
      @apply max-w-520px;
      @apply my-0;
      @apply mx-auto;
      @include font-text('desktop', 'medium');
      @screen md {
        @include font-text('desktop', 'large');
      }
    }
  }

  &__cover-logo {
    @apply mx-auto;
    @apply w-10;
    @apply h-10;
    img {
      @apply w-full h-full object-contain;
      @apply bg-transparent;
    }
  }

  &__links-wrapper {
    @apply w-full h-[184px];
    @apply absolute;
    @apply mb-16;
    @apply bottom-0;
    &--desktop {
      @apply hidden;
      @screen md {
        @apply block;
      }
    }
    &--mobile {
      @apply block relative w-screen;
      left: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      @screen md {
        @apply hidden;
      }
      [class*='featured-hero-block_d-container__links-container'] {
        @apply w-full;
      }
    }
  }

  &__links-container {
    @apply flex;
    @apply flex-col;
    @apply self-center;
    @apply px-[27px];
    @apply py-[30px];
    @apply m-0;
    @apply text-white;
    @apply bg-black;
    @apply w-[264px];
    @apply relative;
    @include font-subtext('mobile', 'medium');
    a {
      @apply flex;
      @apply justify-between;
      @apply items-center;
      @apply text-white;
      @apply py-[14px];
      &:first-child {
        @screen md {
          @apply pt-0;
        }
      }
      &:last-child {
        @screen md {
          @apply pb-0;
        }
      }
    }
    @screen md {
      @apply left-0;
      @include font-subtext('desktop', 'medium');
    }
    @screen lg {
      @apply rounded-lg;
    }
  }

  &__btyb {
    @apply text-white text-xl;
    @apply absolute;
    @apply bottom-0;
    @apply right-0;
    @apply max-w-[75px];
    @apply max-h-[75px];
    @screen md {
      @apply top-[30px] right-[30px];
      @apply bottom-auto;
      @apply max-w-[100px];
      @apply max-h-[100px];
    }
  }

  &__btyb-finance-calculator-overlap {
    @apply text-white text-xl;
    @apply absolute;
    @apply bottom-22;
    @apply right-0;
    @apply left-0;
    @apply flex;
    @apply justify-center;

    a {
      @apply max-w-[75px];
      @apply max-h-[75px];
      @screen md {
        @apply max-w-[100px];
        @apply max-h-[100px];
      }
    }
    @screen md {
      @apply top-[30px] right-[30px] left-auto;
      @apply bottom-auto;
    }
  }
}
