@use '/styles/mixin/fonts.module.scss' as *;
.d-find-a-car {
  @apply w-screen;
  @apply bg-blue-darkest;
  @apply py-6;
  @apply flex;
  @apply justify-center;
  @apply w-full;
  @screen md {
    @apply px-8;
  }

  &--is-page-take-over {
    &::after {
      @apply w-auto;
    }
  }

  &__wrapper {
    @apply max-w-[906px];
  }

  &__legend {
    @include font-subtext('mobile', 'large');
    @apply text-white;
    @apply mx-auto;
    @apply pb-6;
    @apply text-center;
    @screen md {
      @include font-heading('desktop', 'h4');
    }
    @apply font-normal;
  }
  @apply w-full;
  &__btn-row {
    @apply w-full;
    @apply m-auto;
    @apply grid;
    @apply gap-3;
    @apply grid-cols-3;
    @apply justify-center;
    @apply items-center;
  }
  &__btn {
    @apply text-blue-darkest;
    @apply cursor-pointer;
    @apply w-full;
    @apply h-12;
    @apply bg-white;
    @apply rounded-lg;
    @apply my-1;
    @screen md {
      @apply w-44;
      &:last-child {
        @apply mb-0;
      }
    }

    @screen md {
      @apply my-0;
      @apply w-44;
    }
    @screen lmd {
      @apply w-64;
    }
    @screen lg {
      @apply flex-1;
      @apply max-w-[20.5rem]; //328px
    }

    //for non-touch screen true hover only, becaused touch screen stays hovered after click
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        @apply bg-blue-faint;
      }
      @screen lg {
        &:hover {
          @apply text-blue-darkest;
        }
      }
    }

    &--selected,
    &:active {
      @apply text-white;
      @apply bg-[#070D4C];
      &:hover {
        @apply bg-[#070D4C];
        @apply text-white;
      }
    }
    //transition only on non-default states to avoid cls issue
    &:hover,
    &--selected {
      @apply transition-colors;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      @apply duration-500;
    }

    // applied color to link>button directly to avoid cls
    &--link > span {
      @apply rounded-lg;
      @apply bg-white;
      @apply w-full;
      @apply inline-block;
      &:hover {
        @apply bg-blue-faint;
        @apply text-blue-darkest;
      }
    }

    label,
    span {
      @include font-button('desktop');
      @apply px-6;
      @apply h-full;
      @apply w-full;
      @apply flex;
      @apply justify-center;
      @apply items-center;
      @apply cursor-pointer;
    }
  }

  &__label {
    @apply relative;
    &--notselect {
      &:after {
        @apply text-blue-darkest;
        content: '';
        inset: 0;
        @apply absolute;
        @apply rounded-lg;
        @apply opacity-30;
        @apply bg-[#10489E];
      }
    }
  }

  &__search-filter-radio {
    @apply absolute;
    @apply invisible;
    @apply select-none;
  }

  //ssr-suporting styles
  &__search-filter {
    @apply absolute;
    @apply invisible;
    @apply select-none;
  }

  //ssr-suporting styles
  &__search-filter-radio:checked + &__search-filter {
    @apply static;
    @apply visible;
    @apply select-auto;
  }

  &__search-filter-radio:checked + &__search-filter--client-only {
    @apply flex;
  }

  [class*='make-model-search_d-make-model-search__wrapper'] {
    @apply bg-transparent;
    @apply h-auto;
    @apply mt-[10px];
    @apply mb-7;
    @apply mx-auto;
    @screen md {
      @apply mt-6;
      @apply mb-0;
      @apply w-full;
    }
  }
}
