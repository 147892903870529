@use '/styles/mixin/fonts.module.scss' as *;

.d-review-inline-enquiry {
  @apply flex;
  @apply flex-col;
  @apply justify-between;
  @apply items-center;
  @apply rounded-3xl;
  @apply bg-blue-50;
  @apply w-full;
  @apply overflow-visible;
  @apply px-8;
  @apply pb-6;
  @apply mt-14;
  @apply mb-8;
  @apply gap-y-4;
  @screen md {
    @apply pr-6;
    @apply pl-5;
    @apply gap-x-[18px];
    @apply flex-row;
    @apply py-4;
    @apply my-8;
  }
  @screen xl {
    @apply pr-8;
    @apply gap-x-6;
    @apply w-[805px];
    @apply relative;
    left: calc(-403px + 50%);
  }
  &--multi-review {
    @apply relative;
    @apply h-[280px];
    @apply justify-end;
    @screen md {
      @apply h-[112px];
    }
  }

  &__container {
    @apply flex;
    @apply w-full;
    @apply flex-col;
    @apply items-center;
    @apply gap-2;
    @apply justify-between;
    @screen md {
      @apply gap-4;
      @apply justify-start;
      @apply flex-row;
      @apply items-center;
    }
    &--multi-review {
      @apply absolute;
      @apply top-0;
      @apply left-0;
      @apply w-full;
      @apply transform;
      @apply transition-transform;
      @apply duration-1000;
      @apply ease-in-out;
      @screen md {
        width: calc(100% - 192px); //100% - parent x-pdadding - gap between button and price
        @apply top-4;
        @apply left-5;
      }
      @screen xl {
        width: calc(100% - 236px); //100% - parent x-pdadding - gap between button and price
      }
    }

    &__enquire-button {
      @apply w-full;
      @apply min-w-[240px];
      @apply m-0;
      @apply px-12;
      @include font-button('desktop');
      @screen md {
        @apply min-w-[130px];
        @apply px-4.5;
      }
      @screen xl {
        @apply min-w-[160px];
        @apply px-8;
      }
    }
  }
}
