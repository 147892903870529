@use '/styles/mixin/fonts.module.scss' as *;

.d-faqs-accordion {
  p {
    transition: all 0.5s ease-in-out;
    @apply h-auto;
    @apply mt-4;
    @apply overflow-hidden;
    @include font-text('desktop', 'medium');

    @screen lg {
      @apply max-w-[70%];
    }
  }

  a {
    @apply text-blue-dark;
    @apply underline;
  }

  &:global(.open) {
    p {
      @apply max-h-[500px];
    }
  }

  &:global(.closed) {
    p {
      @apply max-h-0;
    }
  }

  &__question {
    @apply flex;
    @apply items-center;
    @apply justify-between;
    @apply cursor-pointer;

    h5 {
      @include font-subtext('mobile', 'large');
      @apply max-w-[90%];

      @screen md {
        @include font-subtext('desktop', 'large');
      }
      @apply m-0;
    }

    span {
      img {
        @apply w-[13px];
        @apply h-[8px];
      }
    }
  }

  &__divider {
    @apply block;
    border-top: 1px solid #000000;
    @apply my-4;
  }
}
