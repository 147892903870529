@use '/styles/mixin/fonts.module.scss' as *;
.drive-articles-title {
  @apply flex;
  @apply mb-2;

  &__title {
    @include font-heading('desktop', 'h4');
    @apply font-semibold;
    @screen md {
      @include font-heading('mobile', 'h4');
      @apply font-semibold;
    }
  }

  &__external-link {
    @apply mt-2;
    @apply hidden;
    @screen lg {
      @apply flex;
    }
  }
}
