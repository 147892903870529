@use '/styles/mixin/fonts.module.scss' as *;

.d-container {
  @apply relative;

  &__heading--wrapper {
    @apply absolute;
    @apply w-full;
    @apply top-[108px];
    @apply z-10;
    @apply text-gray-200;
    @apply text-center;
    @apply pb-3;
    h2 {
      @apply mt-0;
      @apply mb-4;
      @include font-heading('mobile', 'h1');
      @screen md {
        @include font-heading('desktop', 'h1');
      }
    }

    h3 {
      @apply my-0;
      @include font-text('desktop', 'medium');
      @screen md {
        @include font-text('desktop', 'large');
      }
    }

    @screen xs {
      @apply w-[312px];
      top: calc(103px + 2vw);
      @apply left-1/2;
      @apply -translate-x-1/2;
      @apply max-w-[666px];
    }

    @screen sm {
      @apply w-full;
      @apply pt-6;
      @apply pb-3;
      top: calc(115px + 12vw);
    }

    @screen md {
      @apply top-[184px];
    }
  }
}
