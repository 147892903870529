@use '/styles/mixin/fonts.module.scss' as *;

.drive-listing-card-cta {
  &__button-wrapper {
    @apply flex;
    @apply items-center;
    @apply gap-x-1;
    @apply justify-center;
    a {
      @apply contents;
    }
    &__view-button {
      @apply w-full;
      @apply my-0;
      @apply px-0.5;
      @include font-button('desktop');
      &:global(.btn.white) {
        @apply border-[2px];
        @apply border-blue-darkest2;
        @apply text-blue-darkest2;

        &:hover {
          @apply text-blue-darker;
          @apply border-blue-darker;
        }
      }
    }
    &__enquire-button {
      @apply w-full;
      @apply my-0;
      @apply px-0.5;
      @include font-button('desktop');
    }
  }
}
