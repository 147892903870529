@use '/styles/mixin/fonts.module.scss' as *;

.d_container {
  &__shortDescription {
    @include font-text('desktop', 'small');
    @apply mb-2;
    @apply text-gray-500;
    @apply truncate;
  }

  &__name-description {
    @apply static;
    @apply h-20;
    h3 {
      @include font-subtext('mobile', 'large');
      @apply text-black;
      @media (min-width: 980px) {
        @include font-subtext('desktop', 'medium');
        @apply mt-0;
        @apply mb-1;
      }
      @screen xl {
        @apply mb-0;
      }
    }
  }
}
