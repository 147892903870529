.image-carousel {
  @apply relative;
  @apply overflow-hidden;
  @apply w-full;
  @apply aspect-video;

  &__carousel-container {
    @apply flex;
    @apply transition-transform;
    @apply duration-100;
    @apply ease-in-out;
    @apply overflow-hidden;
    @apply rounded-t-2xl;
  }

  &__carousel-slide {
    @apply flex-none;
    @apply box-border;
    @apply w-full;
    @apply h-auto;
  }

  &__carousel-slide {
    img {
      // this supports native lazy loading
      // absolute is used as  position fix is not reliable for lazy loading
      @apply absolute;
      @apply top-[8000px];
    }
    &--active {
      &:first-child {
        @apply transform-none;
      }
      &:nth-child(2) {
        @apply translate-x-[-100%];
      }
      &:nth-child(3) {
        @apply translate-x-[-200%];
      }
      &:nth-child(4) {
        @apply translate-x-[-300%];
      }
      img {
        @apply static;
      }
    }
    &--single {
      @apply transform-none #{!important};
    }
  }

  &__carousel-controls {
    @apply absolute;
    @apply top-1/2;
    @apply -translate-y-1/2;
    @apply flex;
    @apply w-full;
    @apply z-10;

    button {
      @apply px-2;
      @apply py-1;
      @apply text-white;
      @apply border-none;
      @apply cursor-pointer;
      @apply transition;
      @apply duration-[50ms];
      @apply ease-in-out;
      @apply bg-black;
      @apply opacity-60;
      @apply w-8;

      &:hover {
        @apply opacity-100;
      }
    }

    &__carousel-controls-next {
      @apply ml-auto;
      &--disabled {
        @apply opacity-60 #{!important};
      }
      &:hover {
        @apply opacity-100;
      }
      &__hide {
        @apply hidden;
      }
    }

    &__carousel-controls-prev {
      &--disabled {
        @apply hidden;
      }
      &:hover {
        @apply opacity-100;
      }
    }
  }

  &__carousel-dots {
    @apply flex;
    @apply justify-center;
    @apply absolute;
    @apply bottom-5;
    @apply w-auto;
    @apply left-1/2;
    @apply -translate-x-1/2;

    &__carousel-dot {
      @apply w-2;
      @apply h-2;
      @apply rounded-full;
      @apply bg-gray-300;
      @apply m-0.5;
      @apply cursor-pointer;
      @apply transition;
      @apply duration-300;
      @apply ease-in-out;
      @apply opacity-40;

      &:hover {
        @apply bg-gray-700;
      }

      &--active {
        @apply bg-white;
        @apply opacity-100;
      }
    }
  }
}
