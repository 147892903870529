@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/scroll.module.scss' as *;

.drive-registration-lookup__form-dropdown {
  &__wrapper {
    @apply w-full;

    :global(.dropdown) {
      @apply border-gray-100;
      @apply box-border;
      @apply shadow-none;
    }
    :global(.heading) {
      @apply py-[11px];
      @apply pl-2;
      @apply bg-white;
      @apply border-gray-100;
      @apply box-border;
    }
    @screen md {
      @apply pr-4;
      @apply min-w-[149px];
    }
  }
  &-header {
    @include font-text('mobile', 'medium');
    @apply text-gray-600;
  }

  &-placeholder {
    @apply text-gray-500;
  }

  &-options {
    @include hide-scroll;
  }

  &-option-container {
    @apply pl-2;
    @apply text-black;
    span {
      @apply py-2;
    }
  }
  &-option {
    @apply block;
    @apply w-full;
    @apply h-full;
  }
}
