.drive-articles {
  &__glide-slides {
    [class^='glideSlider_glide__track'] {
      @apply pb-8;
    }
    [class^='navigation_glide__arrows'],
    [class^='navigation_glide__bullets'] {
      @apply relative;
    }
    [class*='navigation_glide__arrow'] {
      &::before {
        @apply w-[0.625rem];
        @apply h-[0.625rem];
        @apply p-[0.25rem];
        @apply shadow-none;
        border: solid #000;
        border-width: 0 0.125rem 0.125rem 0;
      }
    }
    [class*='navigation_glide__bullet-item'] {
      @apply bg-black;
    }
  }
  &__slider-link-wrapper {
    @apply flex;
    @apply mb-2;
    @apply justify-end;
  }
  &__slider-link {
    @apply flex;
    @apply mt-5;
    @apply mr-2;
    @screen lg {
      @apply hidden;
    }
  }
}
