@use '/styles/mixin/fonts.module.scss' as *;

.drive-registration-lookup {
  @apply mx-auto;
  @apply px-1;
  @apply min-w-[312px];
  @apply max-w-[584px];
  @apply relative;
  @apply -mt-20;
  @apply mb-12;
  @screen md {
    @apply min-w-[616px];
    @apply max-w-[616px];
    @apply px-0;
    @apply mt-[-134px];
  }
  @screen xl {
    @apply -mt-36;
  }

  &__container {
    @apply bg-blue-faint;
    @apply rounded-lg;
    @apply px-6;
    @apply pt-8;
    @apply pb-10px;
    @apply shadow-xl;
    @screen md {
      @apply px-[34px];
      @apply pb-8;
      @apply pt-[22px];
    }
  }

  h2 {
    @apply text-center;
    @apply m-0;
    @include font-heading('mobile', 'h3');
    @apply font-medium;
    @apply pb-6;
    @screen md {
      @include font-heading('desktop', 'h3');
      @apply pb-[30px];
    }
  }
  &__sub-heading {
    @apply block;
    @apply text-center;
    @apply w-full;
    @apply m-0;
    @apply pb-2;
    @screen md {
      @apply pb-3;
    }
  }
}
