@use '/styles/mixin/fonts.module.scss' as *;
.drive-link__desktop-mobile {
  &__mobile-wrapper {
    @apply flex;
    @apply justify-start;
    @apply mb-6;
    @screen md {
      @apply hidden;
    }
  }
  &__desktop-wrapper {
    @apply hidden;
    @screen md {
      @include font-subtext('desktop', 'medium');
      @apply flex;
      @apply space-x-4;
    }
  }
  &__dropdown-wrapper {
    @apply flex;
    @screen md {
      @apply hidden;
    }
  }
  &__dropdown-header {
    @apply font-semibold;
  }
  &__link-wrapper {
    @include font-subtext('desktop', 'medium');
  }
}

.drive-link__external {
  &__wrapper {
    @apply flex;
    @apply items-start;
    @include font-button;
  }

  &__float-right {
    @apply float-right;
  }

  &__icon {
    @apply fill-current;
    @apply ml-2;
    @apply text-xl;
    @apply mt-0.5;
  }

  &__text {
    @include font-subtext('desktop', 'medium');
  }

  &__icon-inline {
    @apply ml-2;
    @apply text-xl;
    @apply inline;
    @apply mt-0.5;
  }
}

.drive-link__white {
  &__wrapper {
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @apply items-stretch;
    @apply mt-3;
  }
  &__wrapper-single-review {
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @apply items-stretch;
    @apply mt-4;
  }
  &__btn {
    @include font-button;
    &:last-child {
      @apply my-0;
    }
  }
}
