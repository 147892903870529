@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/scroll.module.scss' as *;

.d-find-a-car-dropdown {
  @apply border-b;
  @apply border-b-white;
  @apply w-full;

  @screen md {
    @apply w-auto;
    @apply flex-grow;
  }

  //for ssr, alway show invisible dropdown
  &--ssr > :global(.m-react-select > .m-react-select__menu) {
    @apply invisible;
    @apply h-0;
  }

  &--visible > :global(.m-react-select > .m-react-select__menu) {
    @apply visible;
  }

  &:global(.find-a-car-react-select) {
    @apply border-none;
    @apply my-0;
    @screen md {
      @apply max-w-[15.625rem]; //250px
      @apply m-0;
    }
    @screen lmd {
      @apply max-w-68;
    }
    @screen xl {
      @apply max-w-80;
    }

    :global(.m-react-select__menu) {
      @apply z-20;
      @apply h-auto;
      box-shadow: 0px 4px 16px rgb(0 0 0 / 4%);
      border: 0.5px solid #e3e3e3;
    }

    :global(.m-react-select__control) {
      @apply h-12;
      @apply p-0;
      @apply m-0;
      @apply bg-transparent;
      @apply border-0;
      @apply text-white;
      @apply shadow-none;
      @apply w-full;
    }

    :global(.m-react-select__menu-list) {
      @include custom-scroll;
      @apply max-h-[400px];
    }

    :global(.m-react-select__value-container) {
      @apply pl-3;
      @apply m-0;
    }

    // input text styles
    :global(.m-react-select__input-container),
    :global(.m-react-select__placeholder) {
      @apply m-0;
      @apply pb-3;
      @apply text-white;
      @include font-heading('mobile', 'h4');
      @screen md {
        @include font-heading('desktop', 'h4');
      }
    }

    //indicators states
    :global(.m-react-select__indicators) {
      :global(.drive-icon-ChevronUp) {
        @apply hidden;
      }
      :global(.drive-icon-ChevronDown) {
        @apply flex;
      }
    }
    :global(.m-react-select__control--menu-is-open) {
      :global(.drive-icon-ChevronUp) {
        @apply flex;
      }
      :global(.drive-icon-ChevronDown) {
        @apply hidden;
      }
    }

    :global(.m-react-select) {
      @apply bg-white;
      @apply rounded-lg;
    }

    :global(.m-react-select__placeholder) {
      @apply text-black;
      @apply p-0;
      @include font-subtext('mobile', 'large');
    }

    :global(.m-react-select__input-container) {
      @apply p-0;
      @apply text-black;
    }

    :global(.m-react-select__input) {
      @include font-subtext('mobile', 'large');
    }

    &:global(.non-selected) {
      :global(.m-react-select__placeholder) {
        &:after {
          @include font-subtext('desktop', 'large');
        }
      }
    }
  }

  :global(.m-react-select__indicators) {
    @apply w-[1.875rem];
  }
  //declared at the end to have more css specificity
  &--disabled {
    @apply select-none;
    @apply pointer-events-none;
    &:global(.find-a-car-react-select .m-react-select__placeholder) {
      @apply text-gray-400;
    }
  }
}
