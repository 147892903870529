@use '/styles/mixin/fonts.module.scss' as *;
.drive-testimonials {
  @apply pb-6;
  @apply pt-6;
  @apply w-full;
  @apply z-4;
  @screen xs {
    @apply py-[-10px];
    @apply pb-1;
  }
  @screen md {
    @apply pb-1;
  }
  @screen lg {
    @apply pt-6;
    @apply pb-1;
    @apply px-0;
  }

  &__slider {
    width: calc(100vw - 20px);
    @screen md {
      width: calc(100vw - 20px);
    }
    @screen lg {
      width: calc(50vw + 512px);
    }
    @screen xl {
      @apply w-full;
    }

    [class*='glideSlider_glide__slides--not-mounted'] {
      @apply flex-row;
    }

    [class*='glideSlider_glide'] {
      @apply justify-center;
      @apply pb-8;
      @apply m-auto;
      @screen xs {
        @apply pb-7;
      }
      @screen md {
        @apply ml-0;
      }
    }

    [class*='glideSlider_glide__slides'] {
      @apply pt-1;
    }

    [class*='navigation_glide__arrows'] {
      @apply block;
      @apply relative;
      @apply h-[1px]; //height should be given for arrow's position reference
      width: calc(100vw - 20px * 2);
      @screen md {
        width: calc(100vw - 20px * 2);
      }
      @screen lg {
        @apply w-[1024px];
      }
      @screen xl {
        @apply w-full;
      }
    }

    [class*='navigation_glide__arrow'] {
      @apply -mt-1;
      &::before {
        @apply border-black;
        @apply shadow-none;
        border-width: 0 0.2rem 0.2rem 0;
        @apply w-[0.625rem];
        @apply h-[0.625rem];
        @apply p-[0.25rem];
      }
    }

    [class*='navigation_glide__arrow--left'] {
      @apply left-2;

      //make sure arrow is not right at the edge of screen
      @screen lg {
        @apply ml-4;
      }
      @media screen and (min-width: 1050px) {
        @apply ml-0;
      }
    }

    [class*='navigation_glide__arrow--right'] {
      @apply right-2;

      //make sure arrow is not right at the edge of screen
      @screen lg {
        @apply mr-4;
      }
      @media screen and (min-width: 1050px) {
        @apply mr-0;
      }
    }
    [class*='navigation_glide__bullets'] {
      @apply -left-2.5;
      @screen md {
        @apply -left-7;
      }
      @screen lg {
        @apply left-0;
      }
    }
    [class*='navigation_glide__bullet-item'] {
      @apply bg-black;
    }
  }

  &__slider {
    @apply justify-center;
    @apply pb-4;
  }
}
