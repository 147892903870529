@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/1-settings/colors' as *;
@use '/styles/mixin/truncate.module.scss' as *;
@use '/styles/mixin/imageWrapper.module.scss' as *;
@use '/styles/mixin/thumbnail.module.scss' as *;
@use '/styles/mixin/videoPlayicon.module.scss' as *;

.drive-home-carousel {
  &__wrapper {
    @apply relative;
    @apply mb-4;
    @apply w-screen;
    @apply mx-[-20px];
    @apply h-[320px];
    @apply mt-2;
    @screen md {
      @apply h-full;
      @apply mt-0;
      @apply mt-3;
    }

    @screen lmd {
      @apply mt-4;
    }

    @screen lg {
      @apply w-full;
      @apply mt-5;
      @apply mb-8;
    }

    :global(.gam-impression-tracker) {
      @apply w-[1px];
      @apply h-[1px];
    }

    [class*='navigation_glide__arrows'] {
      @apply bg-red-500;
      @apply w-full;
      @apply top-1/4;
      @apply absolute;
      @screen sm {
        @apply top-1/3;
      }

      @screen lg {
        @apply top-1/2;
      }
    }

    [class*='glideSlider_glide__slides--not-mounted'] {
      @apply flex-row;
      @screen md {
        @apply max-h-[324px];
        @apply block; // for cls fix, so that black right side will have correct height
      }

      @screen lmd {
        @apply max-h-[427px];
      }

      [class*='carousel_drive-home-carousel__card-wrapper'] {
        @apply w-screen;
        @apply h-full;
        @apply relative;
        @screen md {
          @apply max-h-[427px];
          @apply w-full;
        }
      }

      [class*='carousel_drive-home-carousel__img-wrapper'] {
        @screen md {
          @apply max-h-[427px];
          @apply w-full;
        }

        img {
          @apply max-h-[427px];
        }
      }

      [class*='carousel_drive-home-carousel__link-wrapper'] {
        @screen md {
          @apply h-auto;
        }
      }
    }

    [class*='ad-loading-card_drive-article-loading-card__img-wrapper'] {
      @apply w-full;
      @apply h-auto;
      aspect-ratio: 16/9;

      @screen md {
        @apply max-h-[427px];
      }
    }

    [class*='navigation_glide__arrow--left'],
    [class*='navigation_glide__arrow--right'] {
      &::before {
        filter: invert(1);
        @apply w-[12px];
        @apply h-[12px];
        @apply p-0;
        @apply border-black;
        @apply shadow-none;
        border-width: 0 0.2rem 0.2rem 0;
      }
    }

    [class*='navigation_glide__arrow--left'] {
      @apply left-5;
      &::before {
        @apply left-0;
      }

      @screen md {
        @apply left-[30px];
      }
    }

    [class*='navigation_glide__arrow--right'] {
      @apply right-5;
      &::before {
        @apply right-0;
      }
      @screen sm {
        @apply right-[5%];
      }

      @screen lg {
        @apply right-[37%];
      }
    }

    [class*='navigation_glide__bullets'] {
      width: calc(50% - 16px);
      @apply flex;
      @apply justify-between;
      @apply mr-auto;
      @apply bottom-[166px];
      @screen md {
        @apply right-0;
        @apply bottom-[33%];
        width: calc(25% - 16px);
        @apply mr-auto;
      }
      @screen lg {
        @apply bottom-12;
        @apply ml-[20%];
        @apply mr-auto;
      }
    }

    [class*='navigation_glide__bullet-item'] {
      @apply bg-white;
      @apply w-full;
      @apply h-1;
    }
  }
  &__img-link-wrapper {
    > img {
      @apply w-full;
      @apply h-full;
    }
    &:hover {
      @apply cursor-pointer;
    }
  }
  &__dots {
    @apply text-center;
    display: none !important;
    @screen md {
      @apply text-right;
      @apply pr-5;
    }
  }
  &__img-wrapper {
    @apply w-full;
    @apply relative;
    @apply max-h-[196px];
    @screen md {
      @apply max-h-[496px];
      @apply h-full;
    }
    @screen lg {
      @apply max-w-[930px];
    }
    > img {
      @apply w-full;
      @apply h-full;
    }
    &:hover {
      @apply cursor-pointer;
    }

    img {
      @apply object-cover;
      @apply object-center;
    }
    @screen md {
      @apply px-5;
    }
    @screen lg {
      @apply px-0;
    }
  }
  &__review-label {
    @apply text-[12px];
    @apply -bottom-3;
    @apply left-4;
    @apply py-1;
    @apply px-2;
    @apply absolute;
    @apply bg-black;
    @apply text-white;
    @screen sm {
      @apply left-5;
    }
    @screen lg {
      @apply hidden;
    }
  }
  &__review-tag {
    @apply h-6;
    @apply w-fit;

    @apply hidden;
    @apply text-[12px];
    @apply px-2;
    @apply bg-black;
    @apply text-white;
    @apply leading-6;
    @screen lg {
      @apply block;
    }
  }
  &__card-wrapper {
    @apply flex-col;
    @apply flex;
    @apply h-full;

    @screen lg {
      @apply flex-row;
      @apply gap-6;
    }
  }
  &__img-label {
    @apply absolute;
    @apply top-0;
    @apply right-0;
    @apply z-10;
    @apply bg-white/50;
    @apply capitalize;
    @apply text-black;
    @apply pl-2;
    // line-height: 15px;
    @apply pr-2;
    @apply py-2;
    @apply inline-block;
    margin-right: 0.625rem;
    @screen md {
      @apply mr-0;
    }
  }

  &__link-wrapper {
    @apply flex;
    @apply flex-col;
    @apply relative;
    @apply items-start;
    @apply px-4;
    @apply pt-3;
    @apply text-black;
    @apply w-full;
    @apply h-full;
    min-height: 80px;
    @screen sm {
      @apply px-5;
    }
    @screen lg {
      @apply p-8;
      @apply px-6;
      @apply block;
      @apply max-w-[33%];
    }

    @screen lmd {
      max-height: none;
    }

    &:hover {
      @apply no-underline;
      .drive-home-carousel__read-now {
        @apply underline;
      }

      h3 {
        @apply underline;
      }
    }
  }
  &__link {
    @apply mb-0;
    @include font-subtext('mobile', 'large');
    @apply font-medium;
    @apply text-[22px];
    @apply leading-[30px];
    @include truncate(2);
    @apply w-full;
    @screen lmd {
      @include font-heading('desktop', 'h4');
      @apply font-medium;
      @apply pt-8;
    }
    @screen md {
      @apply pt-0;
      @apply text-left;
      @include truncate(8);
      @include font-subtext('desktop', 'medium');
      @apply font-medium;
      @apply text-xl;
    }
    @screen lg {
      @include font-heading('desktop', 'h3');
      @include truncate(4);
      @apply leading-[38px];
      @apply font-medium;
      @apply text-xlarge;
      @apply py-2;
      @apply pb-0;
    }
  }
  &__content {
    @apply hidden;
    @screen md {
      @apply py-2;
      @apply block;
      @apply text-left;
      @apply text-gray-500;
      @include truncate(8);
      @include font-subtext('desktop', 'medium');
      @apply font-normal;
      @apply text-2-xsmall;
    }
    @screen lg {
      @apply text-[16px];
      @apply leading-[27px];
      @apply py-4;
      @apply font-normal;
    }
  }
  &__read-now-wrapper {
    @apply pt-3;
    @apply flex;
    @apply mt-auto;
  }
  &__read-now {
    @include font-subtext('mobile', 'large');
    @apply font-semibold;
    @apply text-blue-dark;
  }
  &__read-now-arrow {
    & img {
      @apply w-6;
      @apply h-6;
      @apply rotate-[315deg];
      @apply -translate-y-[3px];
    }
  }

  &__text-line-wrapper {
    @apply relative;
    @apply flex;
    @apply flex-col;
    @apply justify-between;
    @apply h-full;
    @screen lg {
      @apply h-[90%];
    }
  }
  &__play-btn-wrapper {
    @apply absolute;
    @apply inset-0;
    @apply flex;
    @apply items-center;
    @apply justify-center;
  }
  &__play-icon {
    @apply text-6xl;
    @apply transition-opacity;
    @apply duration-500;
    @screen xs {
      @apply w-10;
      @apply h-10;
    }
    @screen lg {
      @apply w-14;
      @apply h-14;
    }
    &:hover {
      @apply opacity-100;
    }
  }
}

.drive-home-carousel__container {
  &__wrapper {
    @apply min-h-screen;
    @apply z-4;
    @screen md {
      @apply p-0;
    }
  }

  &__container {
    @apply bg-white;
    @screen lg {
      @apply mt-8;
      @apply pt-4;
    }
  }

  &__card-wrapper {
    @apply w-320px;
    @apply mx-auto;

    [class^='articleAnnexCards_drive-annex-cards__home-page-container'] {
      @apply mb-[0.65rem];
    }
    @screen md {
      @apply w-75;
    }
  }

  &__card-heading {
    @apply mt-0;
    @apply mb-3;
    @include font-subtext('mobile', 'large');
    @screen md {
      @include font-subtext('desktop', 'medium');
    }
  }
  &__annex-card {
    @apply mt-0;
    @apply mb-3;
    @include font-subtext('mobile', 'large');
    @screen md {
      @include font-subtext('desktop', 'medium');
    }
  }
  &__annex-card-link {
    @apply hidden;
    @screen md {
      @apply flex;
      @apply self-end;
      @include font-button;
      @apply float-right;
    }
  }

  &__featured-wrapper {
    @apply max-w-full;
    @apply flex-1;
    @apply mb-4;
    @screen md {
      @apply m-0;
    }
    @screen lg {
      @apply min-w-[592px];
      @apply px-[0.625rem];
    }
    // overwriting globle slider 100% implementation only for home carousel
  }

  &__featured-title {
    @apply hidden;
    @apply mt-0;
    @apply mb-3;
    @screen md {
      @apply max-w-[592px];
      @apply px-3;
      @apply flex;
      @include font-subtext('desktop', 'medium');
    }
  }

  &__featured-articles {
    @apply flex;
    @apply flex-col;
    @apply items-start;
    @apply mt-8;
    @screen md {
      @apply max-w-[592px];
      @apply mr-auto;
      @apply ml-auto;
      @apply flex-row;
      @apply justify-between;
      @apply items-center;
    }
  }

  &__featured-articles-wrapper {
    @apply max-w-[592px];
    @apply mr-auto;
    @apply ml-auto;
    & > div {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      @apply pr-0;

      @screen md {
        padding-top: 0.9375rem;
        padding-bottom: 0.9375rem;
      }
    }

    :global(.article-wrapper) {
      @apply flex-auto;

      @screen md {
        @apply w-1/2;
        justify-content: flex-start !important;

        &:nth-child(even) {
          justify-content: flex-end !important;
        }
      }
    }

    :global(.article-thumbnail) {
      height: 19rem;

      :global(.figure) {
        height: 11.25rem;

        @screen md {
          height: 10.4375rem;
        }
      }

      :global(.title) {
        @apply h-16;

        a {
          @apply text-lg;
          -webkit-line-clamp: 2;
        }
      }
    }
  }

  &__featured-articles-title {
    @apply m-0;
    @apply mb-4;
    @include font-heading('mobile', 'h3');
    @apply font-semibold;
    @screen md {
      @include font-heading('desktop', 'h3');
      @apply mb-0;
    }
  }

  &__featured-articles-link {
    @apply mb-2;
    @screen md {
      @apply mb-0;
    }
  }

  &__img-wrapper {
    @apply shrink-0;
  }
  &__img {
    @apply w-103px;
    @apply h-103px;
    @apply rounded-full;
  }

  &__ad-r1 {
    @apply hidden;
    @apply mb-10;

    @screen lg {
      @apply flex;
    }
  }

  &__advice-link {
    @apply hidden;
    @apply self-end;

    @screen md {
      @apply flex;
      @apply mt-[-0.688rem];
      @apply mb-[1.875rem];
    }
  }

  &__ad-pos2 {
    @apply max-w-screen-md;
    @apply m-auto;
    @apply mt-14;
    @apply flex;
    @apply justify-center;
  }

  &__ad-pos3 {
    @apply max-w-screen-md;
    @apply m-auto;
    @apply my-6;
    @apply flex;
    @apply justify-center;
  }

  &__slider-wrapper {
    @apply w-full;
    margin-bottom: 1rem;

    @media (max-width: theme('screens.md')) {
      height: auto;
    }

    /* needed to fix the cls during load as slick slider is adjusting and shifting down by 2 rem */
    @media (min-width: theme('screens.xl')) {
      height: calc(calc(theme('screens.xl') - 584px - 3rem) * 0.5625 + 2rem);
    }

    @media (max-width: 1250px) and (min-width: 1240px) {
      height: calc(calc(theme('screens.xl') - 584px - 3rem) * 0.5625 + 2rem);
    }

    @media (max-width: 1239px) and (min-width: 768px) {
      height: calc(689px * 0.5625 + 2rem);
    }

    :global(.drive-home-carousel__arrow) {
      top: calc((100% - 80px) / 2);
      @screen md {
        top: calc((100% - 40px) / 2);
      }
    }
  }

  &__slider-mobile {
    @apply hidden;
    @media (max-width: 935px) {
      @apply flex;
      width: calc(100vw);
      height: calc(100vw * 0.5625 + 5rem);
      @apply -mt-1;
    }
  }
  &__slider-desktop {
    @apply flex;

    @screen md {
      @apply max-w-[592px];
      @apply ml-auto;
      @apply mr-auto;
    }
    @media (max-width: 935px) {
      @apply hidden;
    }
  }
}

.drive-home-carousel__caption {
  @apply text-center;
  @apply px-5;
  @apply text-white;
  @apply w-full;
  @apply bg-black;
}
