.dml-slider-container {
  @apply mt-7;
  cursor: grab;
  width: calc(100vw - 20px);
  @apply max-w-[100%];

  @screen md {
    @apply mt-4;
  }
  @screen lg {
    width: calc(50vw + 512px);
  }
  @screen xl {
    @apply w-full;
  }
  [class*='glideSlider_glide__slides'] {
    @apply rounded-2xl;
  }
  [class*='card-wrapper glide__slide--active'] {
    @apply rounded-2xl;
  }
  &__card-wrapper {
    @apply rounded-2xl;
    @apply shadow-md;
    @apply bg-white;
    @apply my-1;
    &:first-child {
      @apply ml-[2px];
    }
  }
}
