@use '/styles/mixin/fonts.module.scss' as *;

.drive-regolookup-form {
  @apply flex;
  @apply flex-col;
  @screen md {
    @apply flex-row;
  }

  &__input {
    @apply m-0;

    &__wrapper {
      @apply m-0;

      @screen md {
        @apply pr-4;
      }

      :global(.input) {
        @apply py-[11px];
        @apply bg-white;
        @apply border-gray-100;
        @apply pl-2;
        @apply box-border;
        @include font-text('mobile', 'medium');
        font-size: 16px;

        &:focus,
        &:hover {
          @apply border-gray-100;
          @apply box-border;
        }
        &::placeholder,
        &[type='number']::-webkit-input-placeholder {
          @include font-text('mobile', 'medium');
          @apply text-base; //setting text to 16px to avoid iphone safari auto zoom, there can be more general global fix
          @apply text-gray-500;
          @apply opacity-100;
        }

        /* Hide number input arrows on Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          @apply m-0;
        }

        /* Hide number input arrows on Firefox */
        &[type='number'] {
          -moz-appearance: textfield;
        }

        @screen md {
          @apply min-w-[193px];
        }
      }
      :global(.error) {
        @apply absolute;
      }
    }
  }

  &__field {
    @apply pb-5;
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @apply items-start;
    @apply justify-end;
    @screen md {
      @apply grow-0;
      @apply pb-[30px];
    }
  }
  &__label {
    @apply block;
    @apply relative;
    @apply m-0;
    @include font-text('desktop', 'small');
    @apply text-base; //setting text to 16px to avoid iphone safari auto zoom, there can be more general global fix     @apply text-gray-600;
    @apply pb-[6px];
  }

  &__button {
    @apply w-full;
    @apply my-0;
    @apply mt-2;
    @include font-subtext('desktop', 'large');
    @apply py-[15px];
    @screen md {
      @apply mt-0;
      @apply min-w-36;
      @apply max-w-[292px];
      @apply px-[9px];
    }

    &__wrapper {
      @apply w-full;
      @apply flex;
      @apply justify-center;
      @apply pb-7;
      @screen md {
        @apply pb-6;
      }
    }
  }
}
