@use '/styles/mixin/fonts.module.scss' as *;

.drive-registration-lookup__help {
  @apply block;
  @apply text-center;
  @include font-text('desktop', 'medium');
  @apply text-gray-500;
  @apply pb-4;
  @screen md {
    @apply pb-0;
  }

  span {
    text-decoration: underline;
    @apply text-blue-dark;
    @include font-button('desktop');
    @apply font-bold;
    @apply cursor-pointer;
  }
}
