@use '/styles/mixin/fonts.module.scss' as *;

.d-inline-image-and-title {
  &__img {
    @apply mt-4;
    @apply rounded-lg;
    @apply h-20;
    @apply w-[145px];
    @apply bg-blue-50;

    @screen md {
      @apply mt-0;
    }
  }

  &__title {
    @include font-heading('mobile', 'h4');

    @screen md {
      width: calc(100% - 145px - 16px - 97px); // 100% - image width - col gap - Image info comp
      @include font-heading('mobile', 'h3');
    }

    @screen xl {
      width: calc(100% - 145px - 16px - 142px);
      @include font-standfirst('desktop');
    }
  }
}
