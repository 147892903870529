@use '/styles/mixin/fonts.module.scss' as *;
.drive-recently-added-label {
  @apply bg-white;
  @apply z-10;
  @apply absolute;
  @apply top-[10px];
  @apply left-[10px];
  @apply rounded-[29px];
  @apply h-6;
  @apply w-[127px];
  @apply flex;
  @apply items-center;
  @apply gap-x-[2.5px];
  @apply pl-[10px];
  box-shadow: 0px 2px 8px 0px #00000014;
  @apply pointer-events-none;

  &__dot {
    @apply block;
    @apply h-1;
    @apply w-1;
    @apply p-[5px];
    @apply rounded-full;
    @apply bg-blue-normal;
  }

  &__text {
    @include font-caption('desktop', 'normal');
    @apply text-black;
  }
}
