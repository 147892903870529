.d-cover-hero {
  &__wrapper {
    @apply relative;
    @apply pointer-events-none;
    @apply overflow-hidden;
    @apply w-screen;
    left: calc(-50vw + 50%);
    @apply min-h-[364px];
    @screen xs {
      @apply max-h-[511px];
      height: calc(100vw - 156px);
    }
    &--page-takeover {
      @screen lg {
        margin-left: calc((100vw - 1024px) / 2);
        @apply container;
      }
      @screen xl {
        margin-left: calc((100vw - 1240px) / 2);
        @apply container;
      }
    }
    img {
      @apply object-cover;
      @apply object-center;
      @apply absolute;
      @apply w-full;
      @apply h-full;
    }
  }
}
