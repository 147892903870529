@use '/styles/mixin/fonts.module.scss' as *;

.drive-image-slide-item {
  &__carousel-image {
    @apply w-full;
    @apply h-full;
    aspect-ratio: 16/9;
  }
  &__overlay {
    @apply absolute;
    @apply top-0;
    @apply left-0;
    @apply w-full;
    @apply h-full;
    @apply bg-transparent;
    @apply flex;
    @apply items-end;
    @apply justify-end;

    &__button {
      @apply bg-black;
      @apply opacity-60;
      @apply h-8;
      @apply self-center;
      @apply text-white;
      @apply border-none;
      @apply cursor-pointer;
      @apply mr-[32px];
      @apply px-2;
      @apply ml-2;
    }
  }

  &__sample-image {
    @apply text-center;
    @apply p-1;
    @apply absolute;
    @apply bottom-0;
    @apply w-full;
    @include font-caption('desktop', 'normal');
    @apply bg-blue-faint;
    @apply text-blue-dark;
  }
}
