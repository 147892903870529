@use '/styles/mixin/fonts.module.scss' as *;

.drive-listing-card-price-info {
  @apply w-full;
  @apply flex;
  @apply flex-row;
  @apply text-gray-600;
  @apply mb-4;
  @apply justify-between;

  &__has-drop {
    @apply mb-10px;
  }
  [class*='disclaimer-tooltip'] {
    @apply ml-0.5;
  }
  &__ghostlisting {
    @apply mb-8;
    [class*='disclaimer-tooltip'] {
      @apply items-baseline;
    }
  }

  :global(.wrapper) {
    @apply flex;
    @apply gap-4;
    @apply w-full;
    :global(.amount) {
      @include font-heading('desktop', 'h3');
    }
    :global(.info) {
      @apply flex;
      @apply pl-1px;
      @include font-label('desktop');
    }
  }
  :global(.type) {
    @apply text-gray-400;
    @apply flex;
    @include font-caption('desktop', 'normal');
  }
  :global(.type-placeholder) {
    @apply text-gray-400;
    @apply h-[15px];
  }
  &__drop {
    @apply flex;
    @apply flex-col;
    &-value {
      @include font-subtext('desktop', 'medium');
      @apply font-medium;
      line-height: 32px;
      @apply text-gray-400;
      @apply line-through;
    }
  }

  &__lease {
    @apply relative;
    @apply w-20;
    @apply text-blue-dark;
    @apply h-4;
    @apply mb-2;
    @include font-subtext('mobile', 'medium');
    @apply ml-3;

    @media (min-width: 400px) {
      margin-left: calc(50vw - 175px);
    }

    @screen md {
      @apply ml-3;
    }

    @screen lmd {
      @apply ml-15;
    }

    @screen lg {
      @apply ml-3;
    }

    &__icon {
      @apply absolute;
      @apply top-10;
      @apply -right-2.5;
      @apply w-4;
      @apply h-4;
      @apply cursor-pointer;

      &--bigger-number {
        @apply -right-4;
      }
    }
  }
}
