.d-roofrack {
  @apply h-0;
  @apply hidden;

  @screen lg {
    @apply block;
    @apply h-[250px];
    @apply pt-3;
  }
}
